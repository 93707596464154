import * as values from './values';
import * as regex from './regex';

const Val = {
  // 여기는 CONSTANT_CASE로 정의된 것들을 불러서 쓴다.
  // 설정 개념이 아닌 고정된 문자열이나 숫자값
  ...values,
  ...regex,
};

export default Val;
