import type { StudentT } from 'types/shared/core/Student';
import type {
  EmptyRequestT,
  MultipleStudentRequestT,
} from 'types/shared/reqAndRes/CommonRequest';
import type {
  MyStudentT,
  SortResultT,
  ItemPresentingStatusT,
  StudentWithDescT,
} from 'types/frontend/shared';

import type { GroupTagT } from 'types/shared/core/Post';

import { MyResponse } from 'types/shared/reqAndRes/MyResponse';
import {
  READ_DELETED_STUDENT_LIST,
  READ_STUDENT_LIST,
} from 'network/shared/student';
import BaseStore from 'stores/BaseStore';
import { api } from 'utils/api/api';
import { makeAutoObservable, reaction, runInAction } from 'mobx';
import SC from 'constants/shared';
import ArrayHelper from 'utils/ArrayHelper';
import Val from 'constants/frontend/shared/Val';
import ClsDataStore from './shared/ClsDataStore';
import { MyError } from 'utils/MyError';

console.log('StudentListDataStore');

const studentSortingCriteria = (
  s1: MyStudentT,
  s2: MyStudentT,
): SortResultT => {
  if (s1.name < s2.name) return -1;
  if (s1.name > s2.name) return 1;

  return s1.id === s2.id ? 0 : s1.id < s2.id ? -1 : 1;
};

//
//
//
//
//
//

export class StudentGroup {
  groupName: string;
  groupKey: number;
  filtered?: boolean; // 그룹 차원에서 filter를 통과했는지
  groupTag?: GroupTagT | undefined; // 그룹단위로 선택하면 켜지거나 꺼짐

  // state: 'unselected' | 'partiallySelected' | 'selected' = 'unselected';
  studentList: StudentWithDescT[] = [];

  constructor(groupName: string, groupKey: number, groupTag?: GroupTagT) {
    this.groupName = groupName;
    this.groupKey = groupKey;
    this.groupTag = groupTag;

    makeAutoObservable(this);
  }

  getSelectionStatus = (
    presentingStatusMap: Map<number, ItemPresentingStatusT>,
  ) => {
    const count = this.studentList.filter(
      (s) => s && presentingStatusMap.get(s.id)?.selected,
    ).length;

    return count === this.studentList.length
      ? 'selected'
      : count > 0
        ? 'partiallySelected'
        : 'unselected';
  };

  handleSelect = (
    presentingStatusMap: Map<number, ItemPresentingStatusT>,
  ): boolean | undefined => {
    let isGroupTagSelected: boolean | undefined;

    if (this.getSelectionStatus(presentingStatusMap) !== 'selected') {
      this.studentList.forEach((s) => {
        presentingStatusMap.get(s.id)!.selected = true;
      });

      if (this.groupTag) isGroupTagSelected = true;
    } else {
      this.studentList.forEach((s) => {
        presentingStatusMap.get(s.id)!.selected = false;
      });
      if (this.groupTag) isGroupTagSelected = false;
    }
    return isGroupTagSelected;
  };
}

//
//
//
//
//
//

export default class StudentListDataStore implements BaseStore {
  studentListVersion = ''; // 외부에서 변할 필요가 있을 때 설정하는 값
  studentListTimestamp = ''; // 내부에서 불러오기 완료시 설정하는 값

  clsDataStore!: ClsDataStore;

  studentList: StudentWithDescT[] = [];
  initialCheckedTags: Record<string, true> = {};

  enrolledStudentsOfGrades: Map<keyof typeof SC.Common.Grade, StudentGroup> =
    new Map();

  nonEnrolledStudentsOfGrades: Map<keyof typeof SC.Common.Grade, StudentGroup> =
    new Map();

  studentsOfClasses: Map<number, StudentGroup> = new Map();
  studentIdAndStudentMap: Map<number, MyStudentT> = new Map();

  constructor(clsDataStore: ClsDataStore) {
    makeAutoObservable(this);

    this.clsDataStore = clsDataStore;

    reaction(
      () => this.clsDataStore.clsTimestamp,
      (clsList, prev) => {
        console.log('clsList 가 변해서 할 작업들 시키기', clsList, prev);
        this.makeStudentsOfClasses();
        this.makeStudentClassDescription();
      },
    );
    reaction(
      () => this.studentListVersion,
      () => this.loadStudentList(),
    );
  }

  init = () => {
    // console.log('init');

    // 반정보 - 모든 교사가 조회 가능하므로 언제나 동일한 결과 리턴
    // 루트 스토어에 기억
    // 버전이 갱신되면 다시 가져온다.
    // 학생 배정 정보도 들어 있음

    this.loadStudentList();
  };

  setVersion = (v: string) => {
    this.studentListVersion = v;
  };

  // 그룹단위로 태그를 모은다. (선택된 태그가 아니라 전체 태그)
  getAllTags = () => {
    const tags: GroupTagT[] = [];
    for (const [, value] of this.enrolledStudentsOfGrades.entries()) {
      value.groupTag && tags.push(value.groupTag);
    }
    for (const [, value] of this.studentsOfClasses.entries()) {
      value.groupTag && tags.push(value.groupTag);
    }
    return tags;
  };

  makeStudentClassDescription = () => {
    // console.log('dataStore makeStudentClassDescription');
    for (const student of this.studentList) {
      student.assignedClassNames = [];
      this.clsDataStore.studentClassAssignMap
        .get(student.id)
        ?.forEach((clsId) => {
          const cls = this.clsDataStore.clsMap.get(clsId);
          if (!cls) return;
          student.assignedClassNames?.push(cls.name);
        });
      student.description = student.assignedClassNames.join(', ');
    }
  };

  // getter 형식의 studentOfGrades 만들기
  makeStudentsOfGrades = () => {
    // console.log('dataStore makeStudentsOfGrades');
    // console.log('studentList size', this.studentList.length);
    if (this.studentList.length === 0) {
      console.log('no student');
      return;
    }

    // 맵 속의 기존 학생리스트 제거하기
    for (const group of this.enrolledStudentsOfGrades.values()) {
      group.studentList = [];
    }
    for (const group of this.nonEnrolledStudentsOfGrades.values()) {
      group.studentList = [];
    }

    // this.studentList.forEach((student) => {
    for (const student of this.studentList) {
      const grade = student.grade || 'undef';
      const gradeValue = SC.Common.Grade[grade];

      // 재원생과 외부생으로 나누어 생성
      if (student.status === 'enrolled') {
        if (!this.enrolledStudentsOfGrades.has(grade)) {
          this.enrolledStudentsOfGrades.set(
            grade,
            new StudentGroup(grade, gradeValue, {
              tagType: 'grade',
              tagValue: grade,
              checked: false, // !!this.initialCheckedTags[`grade_${grade}`],
            }),
          );
        }
        this.enrolledStudentsOfGrades.get(grade)?.studentList.push(student);
      } else {
        if (!this.nonEnrolledStudentsOfGrades.has(grade)) {
          this.nonEnrolledStudentsOfGrades.set(
            grade,
            new StudentGroup(grade, gradeValue),
          );
        }
        this.nonEnrolledStudentsOfGrades.get(grade)?.studentList.push(student);
      }
    }
  };

  makeStudentsOfClasses = () => {
    // console.log('dataStore makeStudentsOfClasses');

    if (this.studentList.length === 0) {
      console.log('no student');
      return;
    }

    // 맵 속의 기존 학생리스트 제거하기
    for (const group of this.studentsOfClasses.values()) {
      group.studentList = [];
    }

    const studentWithClass: Set<number> = new Set();

    // 이름순으로 정렬되는 새로운 방법
    const tempStudentIdAndClassIdListMap: Map<number, number[]> = new Map();
    for (const cls of this.clsDataStore.clsItemList) {
      for (const studentId of cls.assignedStudents) {
        if (!tempStudentIdAndClassIdListMap.get(studentId)) {
          tempStudentIdAndClassIdListMap.set(studentId, []);
        }
        tempStudentIdAndClassIdListMap.get(studentId)?.push(cls.id);
        studentWithClass.add(studentId);
      }
    }

    // 가나다 순 정렬을 위해 최상위 수준에서는 학생 먼저 iterate
    for (const student of this.studentList) {
      if (tempStudentIdAndClassIdListMap.has(student.id)) {
        for (const clsId of tempStudentIdAndClassIdListMap.get(student.id)!) {
          const cls = this.clsDataStore.clsMap.get(clsId);
          if (!cls) continue;

          if (!this.studentsOfClasses.has(clsId)) {
            this.studentsOfClasses.set(
              cls.id,
              new StudentGroup(cls.name, cls.id, {
                tagType: 'class',
                tagValue: cls.id.toString(),
                checked: false, // !!this.initialCheckedTags[`grade_${clsId}`],
              }),
            );
          }

          // 학생 넣기
          this.studentsOfClasses.get(clsId)?.studentList.push(student);
        }
      }
    }

    // 속한 클래스가 없는 학생 그룹 만들기
    this.studentsOfClasses.set(
      Val.NO_CLASS_ID,
      new StudentGroup('속한 클래스가 없는 학생', Val.NO_CLASS_ID),
    );
    if (this.studentList.filter((s) => !studentWithClass.has(s.id)).length) {
      this.studentsOfClasses.get(Val.NO_CLASS_ID)?.studentList.push(
        ...this.studentList.filter(
          (s) => !studentWithClass.has(s.id) && s.status === 'enrolled', // 재원생이지만 반이 없는 경우에만 "속한 클래스가 없는 학생"이 됨
        ),
      );
    }
  };

  updateStudentList = (list: StudentT[]) => {
    // console.log('dataStore updateStudentList');
    const deletedItems = ArrayHelper.mergeArray<MyStudentT, number>(
      this.studentList,
      list.sort(studentSortingCriteria),
      'id',
      studentSortingCriteria,
    );

    for (const id of deletedItems) {
      this.studentIdAndStudentMap.delete(id);
    }
    for (const student of this.studentList) {
      this.studentIdAndStudentMap.set(student.id, student);
    }

    this.makeStudentClassDescription();
    this.makeStudentsOfGrades();
    this.makeStudentsOfClasses();

    this.studentListTimestamp = new Date().toString();
  };

  //
  //
  //
  loadStudentList = async () => {
    console.log('dataStore loadStudentList');
    // 학생 목록
    // - 기본적으로는 자신이 담당하는 학생만 불러온다.
    // - 일반교사 & 자신이 담당하는 학생 이외에는 주소/전화번호등 일부 개인정보가 보이지 않는다.
    try {
      const result = await api<EmptyRequestT, MyResponse<StudentT>>(
        READ_STUDENT_LIST(0),
        {},
      );

      if (result.isSuccess && result.objects) {
        this.updateStudentList(result.objects);
      }
    } catch (e) {
      console.error(e);
    }
  };

  getDeletedStudentList = async (studentIdListToCheck: number[]) => {
    const deletedStudentGroup: StudentGroup = new StudentGroup('삭제생', 0);

    const deletedStudentIdSet = new Set(studentIdListToCheck);
    this.studentList.forEach((s) => {
      deletedStudentIdSet.delete(s.id);
    });
    const deletedStudentIdList = Array.from(deletedStudentIdSet);
    console.log('deleted students = ', deletedStudentIdList);

    if (deletedStudentIdList.length) {
      try {
        const result = await api<MultipleStudentRequestT, MyResponse<StudentT>>(
          READ_DELETED_STUDENT_LIST(0),
          {
            studentIdList: deletedStudentIdList,
          },
        );

        if (result.isSuccess && result.objects) {
          deletedStudentGroup.studentList = result.objects.map(
            (s: StudentT): MyStudentT => ({
              ...s,
            }),
          );

          console.log('deleted students = ', deletedStudentGroup.studentList);

          // 얻어온 삭제생의 정보도 map에 넣어둔다
          runInAction(() => {
            deletedStudentGroup.studentList.map((s) =>
              this.studentIdAndStudentMap.set(s.id, s),
            );
          });
        } // end of if
      } catch (e) {
        MyError.handleCatchedError(e);
      }
    }
    return deletedStudentGroup;
  };

  getName = (studentId: number): string => {
    const student = this.studentIdAndStudentMap.get(studentId);
    if (!student) return '';
    return student.name;
  };
}
