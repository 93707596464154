import { ClsT } from 'types/shared/core/Cls';
import type { MyResponse } from 'types/shared/reqAndRes/MyResponse';
import type { EmptyRequestT } from 'types/shared/reqAndRes/CommonRequest';
import { READ_CLS_LIST } from 'network/shared/cls';
import { api } from 'utils/api/api';

import { makeAutoObservable, reaction, runInAction } from 'mobx';
import BaseStore from 'stores/BaseStore';

class ClsDataStore implements BaseStore {
  clsVersion = '';
  clsTimestamp = ''; // 내부에서 불러오기 완료시 설정하는 값

  // 해당 원의 모든 클래스 정보 - 운영중, 운영종료 모두, 삭제된 클래스는 불러오지 않음
  clsMap: Map<number, ClsT> = new Map();

  // 서버에서 원하는 순서로 정렬된 클래스 id 순열
  clsIdList: number[] = [];
  clsItemList: ClsT[] = [];

  isLoaded = false;

  constructor() {
    makeAutoObservable(this);

    reaction(
      () => this.clsVersion,
      () => this.loadClsList('ClsDataStore'),
    );
  }

  init = (name?: string) => {
    this.loadClsList(name);
  };

  setVersion = (v: string) => {
    this.clsVersion = v;
  };

  updateClsList = (list: ClsT[]) => {
    this.clsIdList.length = 0;
    this.clsItemList.length = 0;
    list.forEach((cls) => {
      this.clsIdList.push(cls.id);
      this.clsItemList.push(cls);
      this.clsMap.set(cls.id, cls);
    });

    this.clsTimestamp = new Date().toString();
    console.log('clsTimestamp 바뀜');
  };

  async loadClsList(name?: string) {
    console.log('loadClsList', name);
    try {
      const result = await api<EmptyRequestT, MyResponse<ClsT>>(
        READ_CLS_LIST(0),
        {},
      );

      runInAction(() => {
        if (result.isSuccess && result.objects) {
          this.isLoaded = true;
          this.updateClsList(result.objects as ClsT[]);
        } else {
          this.clsIdList = [];
          this.clsMap = new Map();
        }
      });
    } catch (e) {
      console.log('clsDataStore load error', e);
    }
  }

  // compute로 작동
  get studentClassAssignMap(): Map<number, number[]> {
    // 클래스별 학생 배정 정보를 가지고
    // 학생별 클래스 배정 정보를 만든다.
    console.log('studentClassAssignMap');
    const studentClassAssignMap: Map<number, number[]> = new Map();
    this.clsIdList.forEach((clsId) => {
      const cls = this.clsMap.get(clsId);
      if (!cls) return;
      cls.assignedStudents.forEach((studentId) => {
        if (!studentClassAssignMap.has(studentId)) {
          studentClassAssignMap.set(studentId, []);
        }
        studentClassAssignMap.get(studentId)?.push(clsId);
      });
    });
    return studentClassAssignMap;
  }

  // compute로 작동
  get teacherClassAssignMap(): Map<number, number[]> {
    // 클래스별 교사 배정 정보를 가지고
    // 교사별 클래스 배정 정보를 만든다.
    const teacherClassAssignMap: Map<number, number[]> = new Map();

    for (const clsId of this.clsIdList) {
      const cls = this.clsMap.get(clsId);
      if (!cls) continue;
      for (const teacher of cls.assignedTeachers) {
        const teacherId = teacher.teacherId;
        if (!teacherClassAssignMap.has(teacherId)) {
          teacherClassAssignMap.set(teacherId, []);
        }
        teacherClassAssignMap.get(teacherId)?.push(clsId);
      }
    }
    return teacherClassAssignMap;
  }
}

export default ClsDataStore;
