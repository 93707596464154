import { API } from 'types/frontend/shared';
//
//
//
// 클래스 관련
//

// 클래스 리스트
// 모든 클래스와, 배정된 학생id를 얻어옴
export const READ_CLS_LIST = (academyId: number): API => ({
  method: 'GET',
  path: `/academy/${academyId}/cls`,
});

// 간단 클래스 리스트
// 클래스의 id와 이름만
export const READ_SIMPLE_CLS_LIST = (academyId: number): API => ({
  method: 'GET',
  path: `/academy/${academyId}/cls?simple=true`,
});

// 클래스 상세 상세
export const READ_CLS_DETAIL = (academyId: number, clsId: number): API => ({
  method: 'GET',
  path: `/academy/${academyId}/cls/${clsId}`,
});

// 클래스 추가
export const CREATE_CLS = (academyId: number): API => ({
  method: 'POST',
  path: `/academy/${academyId}/cls`,
});

// 클래스 수정
export const UPDATE_CLS = (academyId: number, clsId: number): API => ({
  method: 'PUT',
  path: `/academy/${academyId}/cls/${clsId}`,
});

// 클래스 상태 수정
export const UPDATE_CLS_STATUS = (academyId: number, clsId: number): API => ({
  method: 'PUT',
  path: `/academy/${academyId}/cls/${clsId}/status`,
});

// 클래스에 교사 배정
export const UPDATE_CLS_TEACHER_ASSIGNMENT = (
  academyId: number,
  clsId: number,
): API => ({
  method: 'PUT',
  path: `/academy/${academyId}/cls/${clsId}/teacherAssignment`,
});

// 클래스에 학생 배정
export const UPDATE_CLS_STUDENT_ASSIGNMENT = (
  academyId: number,
  clsId: number,
): API => ({
  method: 'PUT',
  path: `/academy/${academyId}/cls/${clsId}/studentAssignment`,
});

// 클래스 삭제
export const DELETE_CLS = (academyId: number, clsId: number): API => ({
  method: 'DELETE',
  path: `/academy/${academyId}/cls/${clsId}`,
});
