export const MOBILE_PHONE_REGEX = /^01([0|1|6|7|8|9])([0-9]{3,4})([0-9]{4})$/;
export const CORDED_PHONE_REGEX =
  /^((0(2|3[1-3]|4[1-4]|5[1-5]|6[1-4]|70))(\d{3,4})|(1\d{3}))(\d{4})$/;
export const PHONE_REGEX =
  /^((0(1[0|1|6|7|8|9]|2|3[1-3]|4[1-4]|5[1-5]|6[1-4]|70))(\d{3,4})|(1\d{3}))(\d{4})$/;
export const FORMATTED_MOBILE_PHONE_REGEX =
  /^(01[0|1|6|7|8|9])[-._]?([0-9]{3,4})[-._]?([0-9]{4})$/;

export const KOR_ENG_SPACE_REGEX = /^([ㄱ-ㅎ|가-힣|a-z|A-Z|0-9|][ ]?)+$/;
// 아직 안쓰고 있음
export const CLASS_NAME_REGEX = /^([ㄱ-ㅎ|가-힣|a-z|A-Z|0-9|][ ]?)+$/;

// 교사id는 영어소문자나 숫자로 구성
export const ENG_LOWERCASE_NUMBER_REGEX = /^([a-z|0-9|])+$/;

// 이메일과 교사id(ENG_LOWERCASE_NUMBER_REGEX) 조합
export const LOGIN_ID_REGEX =
  /(^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$)|(^([a-z|0-9|])+@[0-9]+$)/;

export const STUDENT_SIDE_ACADEMY_REGEX =
  /^(\/studentSide\/academy\/)([0-9]+)(\/)/;

export const ACCOUNT_NUMBER_REGEX =
  /(카카오뱅크|KEB하나|하나|SC제일|국민|신한|외환|우리|한국시티|시티|경남|광주|대구|부산|전북|제주|기업|농협|수협|한국산업|새마을금고)(은행)?\s*(\d+[-\s\d]*\d+)/;

export const HOMEWORK_TAG_LIST_REGEX =
  /^[a-zA-Z0-9 [\](),_\u1100-\u11FF\u3130-\u318F\uAC00-\uD7AF]+$/;
