// 단순 숫자 상수들

export const NO_CLASS_ID = 0;

export const INITIAL_URL_AFTER_SIGNING_IN =
  '/teacherSide/management/academy/detail';

export const THEMES = {
  LIGHT: 'LIGHT',
  DARK: 'DARK',
};

export const BULK_STUDENTS_XLSX_TEMPLATE_PATH =
  '/cdn/anotherclass-students-v4.xlsx';

export const HOMEWORK_PRESET_XLSX_TEMPLATE_PATH =
  '/cdn/anotherclass-homeworks-v1.xlsx';

export const PLAYSTORE_APP_LINK =
  'https://play.google.com/store/apps/details?id=com.anotherclass.android.release';

export const APPSTORE_APP_LINK =
  'https://apps.apple.com/us/app/%EC%96%B4%EB%82%98%EB%8D%94%ED%81%B4%EB%9E%98%EC%8A%A4-%ED%95%99%EC%9B%90%EC%95%B1/id1621066744';

export const BACK_AFTER_EDITING = '{ "edited": true }';

export const DUMMY_DATETIME = new Date('1000-01-01');
export const DUMMY_DATE = 10000101;

export const HOMEWORK_TAG_LIST_GUIDE =
  '한글,영어,숫자,[],(),_만 허용됩니다. (최대 100글자)';
