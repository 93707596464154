//
// 학부모/교사 상관없이 필요한 api
// 가입/로그인/자기정보/인증번호를 위한 api의 모음
//
import { API } from 'types/frontend/shared';

// 회원가입
export const DO_SIGN_UP: API = {
  method: 'POST',
  path: '/user/signUp',
};

// 로그인
export const DO_SIGN_IN: API = {
  method: 'POST',
  path: '/user/signIn',
};

// 토큰 갱신
export const DO_REFRESH_ACCESS_TOKEN: API = {
  method: 'POST',
  path: '/user/refreshAccessToken',
};

// 리프레시 토큰 유효성 확인
export const DO_VERIFY_REFRESH_TOKEN: API = {
  method: 'POST',
  path: '/user/verifyRefreshToken',
};

export const DO_REMOVE_ACCOUNT_FROM_DEVICE: API = {
  method: 'POST',
  path: '/user/removeAccountFromDevice',
};

// 인증번호 요청
export const CREATE_VERIFICATION_CODE: API = {
  method: 'POST',
  path: '/user/verificationCode',
};

// 인증번호 확인
export const DO_VERIFY_PHONE_NUMBER: API = {
  method: 'POST',
  path: '/user/verifyPhoneNumber',
};

// 14세 미만 학생의 학부모 동의 기록 남기기
export const CREATE_CHILD_CONSENT_LOG: API = {
  method: 'POST',
  path: '/user/childConsentLog',
};

// 나의 정보
export const READ_MY_INFO: API = {
  method: 'GET',
  path: '/user/myInfo',
};

// 나의 정보 수정
export const UPDATE_MY_INFO: API = {
  method: 'PUT',
  path: '/user/myInfo',
};

// 탈퇴
export const DO_WITHDRAWAL: API = {
  method: 'POST',
  path: '/user/withdrawal',
};

// 더미 프로필 얻기
export const DO_GENERATE_DUMMY_PROFILE: API = {
  method: 'GET',
  path: '/user/dummyProfile',
};

// 프로필 업데이트
export const UPDATE_PROFILE: API = {
  method: 'PUT',
  path: '/user/profile',
};

// 전화번호 업데이트
export const UPDATE_USER_PHONE_NUMBER: API = {
  method: 'PUT',
  path: '/user/modifyPhoneNumber',
};

// 약관
export const READ_AGREEMENT = (type: 'tos' | 'pp'): API => ({
  method: 'GET',
  path: `/user/agreement/${type}`,
});

// 마케팅 동의
export const READ_MARKETING_INFO_CONSENT: API = {
  method: 'GET',
  path: '/user/marketingInfoConsent',
};

// 마케팅 동의
export const UPDATE_MARKETING_INFO_CONSENT: API = {
  method: 'PUT',
  path: '/user/marketingInfoConsent',
};

// 아이디/비밀번호 찾기 - 아이디 얻어내기
export const READ_ACCOUNT_EMAIL_LIST: API = {
  method: 'POST', // read이지만 token을 전달해야 해서 post 선택
  path: '/user/accountEmail',
};

// 아이디/비밀번호 찾기 - 비번 재설정 이메일 보내기
export const DO_SEND_PW_RESET_EMAIL: API = {
  method: 'POST',
  path: '/user/sendPwResetEmail',
};

export const DO_VERIFY_RESET_TOKEN: API = {
  method: 'POST',
  path: '/user/verifyResetToken',
};

export const DO_RESET_PW: API = {
  method: 'POST',
  path: '/user/resetPw',
};
